import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import "./index.sass";
import useRazorpay from "react-razorpay";
import StyledButton from "../../components/Button/StyledButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import OtpInput from "react-otp-input";
import lab from "../../images/lab.svg";
import labtest from "../../images/labtest.svg";
import verified from "../../images/verified.svg";
import expired from "../../images/expired.svg";
import blood from "../../images/blood.svg";
import xray from "../../images/xray.svg";
import ecg from "../../images/ecg.svg";
import morning from "../../images/morning.png";
import afternoon from "../../images/afternoon.png";
import evening from "../../images/evening.png";
import healthVital from "../../images/bmi.svg";
import bpimg from "../../images/bp.svg";
import heightimg from "../../images/height.svg";
import weightimg from "../../images/weight.svg";
import hwratioimg from "../../images/hwratio.svg";
import pulseimg from "../../images/pulse.svg";
import confirm from "../../images/confirm.png";
import audiometryImg from "../../images/audiometry.png";
import visionImg from "../../images/vision.png";
import doctorImg from "../../images/doctor.png";
import completed from "../../images/completed.svg";
import ScheduleRoundedIcon from "@mui/icons-material/ScheduleRounded";
import pftImg from "../../images/pft.svg";
import {
  postMobileForOtp,
  getCentreByKey,
  postVerifyOtp,
  getPartnerKey,
  createCampBooking,
  updateHealthVital,
  getTestStatus,
  postTestStatus,
  updateTestStatus,
  getBooking,
  getEmailOtp,
  postOtpForEmail,
  getHealthVital,
  smsCampCompleted,
  getPackagesData,
  getParams,
  getSlots,
  sendConfirmBooking,
  updateConfirmBooking,
} from "../../services/actionSlice";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  Box,
  Card,
  InputAdornment,
  Typography,
  TextField,
  Grid,
  Dialog,
  Slide,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  LinearProgress,
  Checkbox,
  Tooltip,
  Button,
} from "@mui/material";
import { AppDispatch } from "../../storage/store";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Loader from "../../components/Loader";
import SnackBar from "../../components/SnackBar/snackBarPopPup";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
const theme = createTheme({
  palette: {
    primary: {
      main: "#E00646",
    },
    secondary: {
      main: "#2677F0",
    },
  },
});

export default function Main() {
  // const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const timer = useRef<any>(0);

  const [openPhleboCode, setOpenPhleboCode] = useState<boolean>(false);
  const [phleboCode, setPhleboCode] = useState<string>("");
  const [phleboCodeInp, setPhleboCodeInp] = useState<string>("");
  const [selectedCard, setSelectedCard] = useState<string>("");
  const [verifiedCard, setVerifiedCard] = useState<any>({
    blood: false,
    urine: false,
    ecg: false,
    xray: false,
    pft: false,
    health_vital: false,
    audiometry: false,
    vision: false,
    doctor: false,
  });
  const [isSelected, setIsSelected] = useState<string>("");

  const [screen, setScreen] = useState<string>("otp");
  const [otp, setOtp] = React.useState("");
  const [sendOtp, setSendOtp] = useState<boolean>(false);
  const [fastingRequired, setFastingRequired] = useState<string>("");
  const [campStarted, setCampStarted] = React.useState<any>(true);
  const [campExpired, setCampExpired] = React.useState<any>(false);

  const [refData, setRefData] = useState<any>("");
  const [bookingId, setBookingId] = useState<string>("");
  const [hId, setHId] = useState<string>("");
  const [customerName, setCustomerName] = useState<any>("");
  const [bar, setBar] = useState<number>(0);
  const [barUnit, setBarUnit] = useState<number>(1);
  const [customerNumber, setCustomerNumber] = useState<any>("");
  const [customerEmail, setCustomerEmail] = useState<any>("");
  const [customerAge, setCustomerAge] = useState<any>("");
  const [customerGender, setCustomerGender] = useState<any>("");
  const [customerPackages, setCustomerPackages] = useState<any>([]);
  const [customerAddress, setCustomerAddress] = useState<any>("");
  const [addressInput, setAddressInput] = useState<any>("");
  const [view, setView] = useState<any>("");

  const [mobileValidation, setMobileValidation] = useState<any>(true);
  const [nameValidation, setNameValidation] = useState<any>(true);
  const [expand, setExpand] = useState<any>(true);
  const [expandPatient, setExpandPatient] = useState<any>(true);
  const [ageValidation, setAgeValidation] = useState<any>(true);
  const [employeeId, setEmployeeId] = useState<string>("");
  const [campValid, setCampValid] = useState<boolean>(false);
  const [hwRatio, setHwRatio] = useState<any>("");
  const [seconds, setSecond] = useState<any>(30);
  const [otpEmailVerified, setOtpEmailVerified] = useState<boolean>(false);
  const [height, setHeight] = useState<any>("");
  const [weight, setWeight] = useState<any>("");
  const [bpHigh, setBpHigh] = useState<any>("");
  const [bpLow, setBpLow] = useState<any>("");
  const [pulse, setPulse] = useState<any>("");
  const [hip, setHip] = useState<any>("");
  const [waist, setWaist] = useState<any>("");
  const [bmi, setBmi] = useState<any>("");
  const partnerKey = useSelector((state: any) => state.webAppSlice.partnerKey);
  const packages = useSelector((state: any) => state.webAppSlice.packageParams);
  const [accessToken, setAccessToken] = useState<string>("");
  //data

  const [openParam, setOpenParam] = useState<boolean>(false);
  const params = useSelector((state: any) => state.webAppSlice.params);
  const centreData = useSelector((state: any) => state.webAppSlice.centreByKey);
  const verifiedOtp = useSelector((state: any) => state.webAppSlice.verifyOtp);
  const bookingData = useSelector(
    (state: any) => state.webAppSlice.bookingData
  );

  const updateTestStatusData = useSelector(
    (state: any) => state.webAppSlice.updateTestStatusData
  );
  const loading = useSelector((state: any) => state.webAppSlice.loading);
  const postTestStatusData = useSelector(
    (state: any) => state.webAppSlice.postTestStatusData
  );
  const testStatus = useSelector((state: any) => state.webAppSlice.testStatus);
  const confirmBookingPay = useSelector(
    (state: any) => state.webAppSlice.confirmBooking
  );
  const healthVitalData = useSelector(
    (state: any) => state.webAppSlice.healthVital
  );
  const confirmBooking = async (id: any) => {
    const data = {
      is_confirmed: true,
      booking_id: id,
    };
    await dispatch(
      updateConfirmBooking({
        booking_id: id,
        key: partnerKey?.center_key
      })
    );
    await dispatch(
      sendConfirmBooking({ data: data, key: partnerKey?.center_key })
    );
  };
  const Razorpay = useRazorpay();
  const handlePayment = async (data: any) => {
    const options: any = {
      key:
        process.env.REACT_APP_ENV === "production"
          ? "rzp_live_sDovuuBgCu9dw3"
          : "rzp_test_kBEHIT9OH5OYyo",
      amount: data?.booking_price * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Redcliffe Labs",
      description: "camp iframe transaction",
      image:
        "https://static-redcliffelabs.s3.amazonaws.com/media/gallary-file/None/a0f82e2b-5cee-4694-a972-2514e9bd3694.svg",
      handler: function (response: any) {
        console.log(response?.razorpay_payment_id, "Payment Success");
        setScreen("loading");
        confirmBooking(data?.a_booking_id);
      },
      prefill: {
        name: customerName,
        email: customerEmail,
        contact: customerNumber,
      },
      notes: {
        bookingid: data?.a_booking_id,
      },
      theme: {
        color: "#e10847",
      },
      // callback_url : `${Prefix.api}/payment/order_payment_link_status/`,
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response: any) {
      // sendPayment(response?.error?.metadata?.payment_id);

      alert(response.error.reason);
    });

    rzp1.open();
  };
  useEffect(() => {
    let calcbmi: number = 0;
    if (height && weight) calcbmi = (weight * 10000) / (height * height);
    setBmi(Number(calcbmi).toFixed(1));
  }, [height, weight]);
  const [emailValidation, setEmailValidation] = useState<any>(true);
  const [landingPage, setLandingPage] = useState<boolean>(false);
  //link queries
  // console.log(file, "file.prev ");
  useEffect(() => {
    if (partnerKey?.center_key !== undefined && centreData.multi_package) {
      dispatch(
        getPackagesData({
          url:
            customerGender !== "other"
              ? `?ac_config_id=${centreData.id}&recommended_gender=${customerGender}`
              : `?ac_config_id=${centreData.id}`,
          key: partnerKey?.center_key,
        })
      );
    }
  }, [partnerKey]);
  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const partner_id = params.get("pid");
    const booking_id = params.get("tid");
    const h_id = params.get("hid");
    const name = params.get("name");

    if (partner_id) {
      setRefData(partner_id);
    }
    if (booking_id) {
      setBookingId(booking_id);
    }
    if (h_id) {
      setHId(h_id);
      setScreen("confirmation");
    }
    if (name) {
      setCustomerName(name);
    }
    const mobile = params.get("customer_phone");
    if (mobile) {
      setCustomerNumber(mobile);
    }
    const gender = params.get("gender");
    if (gender) {
      setCustomerGender(gender);
    }
    const email = params.get("email");
    if (email) {
      setCustomerEmail(email);
    }
    const age = params.get("age");
    if (age) {
      setCustomerAge(age);
    }
  }, [window.location.search]);
  useEffect(() => {
    if (refData) dispatch(getCentreByKey(refData));
  }, [refData]);

  useEffect(() => {
    if (updateTestStatusData?.id) {
      setVerifiedCard(updateTestStatusData);
    } else if (testStatus?.results && testStatus?.results.length > 0) {
      setVerifiedCard(testStatus?.results[0]);
    }
    // else if(testStatus?.results && testStatus?.results.length ===0){
    //   createTestStatus()
    // }
  }, [testStatus, updateTestStatusData]);
  // console.log(testStatus, "testStatus");
  const snackData = useSelector((state: any) => state.webAppSlice.snackbarData);

  const campBooking = useSelector(
    (state: any) => state.webAppSlice.campBooking
  );
  // const emailSent = useSelector((state: any) => state.webAppSlice.emailOtp);
  const verifiedOtpEmail = useSelector(
    (state: any) => state.webAppSlice.emailOtpVerified
  );
  const emailSent = useSelector((state: any) => state.webAppSlice.emailOtp);

  const blockInvalidChar = (e: React.KeyboardEvent<HTMLDivElement>) =>
    ["e", "E"].includes(e.key) && e.preventDefault();

  useEffect(() => {
    let today = moment().format("YYYY-MM-DDTHH:mm:ss");
    if (centreData?.center) {
      dispatch(getPartnerKey(centreData?.center));
      setCampStarted(
        moment(today).isBetween(
          moment(centreData?.date_of_activity_start).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          moment(centreData?.date_of_activity_end).format("YYYY-MM-DDTHH:mm:ss")
        )
      );
      setCampExpired(
        moment(today).isAfter(
          moment(centreData?.date_of_activity_end).format("YYYY-MM-DDTHH:mm:ss")
        )
      );
    }
    if (centreData?.status === "ACTIVE") {
      setCampValid(true);
    } else {
      setCampValid(false);
    }
    if (centreData?.no_verification && customerNumber && screen === "otp") {
      setScreen("form");
      dispatch(
        getBooking({
          data: { customer_mobile: customerNumber },
          key: centreData?.id,
        })
      );
    }
  }, [centreData]);
  useEffect(() => {
    let tempPkg: any = [];
    let fasting = fastingRequired === "yes" ? true : false;
    if (
      centreData.packages &&
      centreData.packages.length > 0 &&
      centreData.package_filter &&
      centreData.package_filter.length > 0 &&
      customerAge &&
      customerGender &&
      fastingRequired &&
      !centreData.multi_package
    ) {
      centreData.package_filter.map((val: any) => {
        let hasFasting =
          val?.fasting_required !== undefined
            ? fasting === val?.fasting_required
            : true;
        if (
          val?.gender === customerGender &&
          val?.operator === "gt" &&
          Number(customerAge) > Number(val?.age) &&
          hasFasting
        ) {
          tempPkg.push(val?.id);
        } else if (
          val?.gender === customerGender &&
          val?.operator === "lteq" &&
          Number(customerAge) <= Number(val?.age) &&
          hasFasting
        ) {
          tempPkg.push(val?.id);
        } else if (
          val?.operator === "" &&
          val?.gender === customerGender &&
          hasFasting
        ) {
          tempPkg.push(val?.id);
        } else if (val?.operator === "" && val?.gender === "" && hasFasting) {
          tempPkg.push(val?.id);
        } else if (
          val?.gender === "" &&
          val?.operator === "gt" &&
          Number(customerAge) > Number(val?.age) &&
          hasFasting
        ) {
          tempPkg.push(val?.id);
        } else if (
          val?.gender === "" &&
          val?.operator === "lteq" &&
          Number(customerAge) <= Number(val?.age) &&
          hasFasting
        ) {
          tempPkg.push(val?.id);
        }
      });
      setCustomerPackages(tempPkg);
    } else if (
      centreData.packages &&
      centreData.packages.length > 0 &&
      customerAge &&
      customerGender &&
      fastingRequired &&
      !centreData.multi_package
    ) {
      setCustomerPackages(centreData.packages);
    }
  }, [centreData, customerAge, customerGender, fastingRequired]);
  // console.log(customerPackages,"------",centreData.package_filter)
  useEffect(() => {
    let timeout: any;
    if (seconds > 0) {
      timeout = setTimeout(
        () => setSecond((previousCount: number) => previousCount - 1),
        1000
      );
    } else {
      setSecond(0);
    }
    return () => clearTimeout(timeout);
  });

  const proceedOtp = async () => {
    if (centreData?.verify_via === "M") {
      await dispatch(
        postMobileForOtp({
          phone_number: customerNumber,
        })
      );
      setSendOtp(true);
      setSecond(30);
    } else if (centreData?.verify_via === "E") {
      await dispatch(getEmailOtp(`?email=${customerEmail}&emp_id=123`));
    }
  };
  useEffect(() => {
    if (emailSent?.message === "Otp sent!") {
      setSendOtp(true);
      setSecond(30);
    }
  }, [emailSent]);
  useEffect(() => {
    verifyMobile();
  }, [customerNumber]);
  useEffect(() => {
    if (String(otp).length === 6 && centreData?.verify_via === "M") {
      dispatch(
        postVerifyOtp({
          phone_number: customerNumber,
          otp: otp,
        })
      );
    } else if (String(otp).length === 6 && centreData?.verify_via === "E") {
      dispatch(postOtpForEmail(`?otp=${otp}&email=${customerEmail}`));
    }
  }, [otp]);

  const verifyEmail = () => {
    const nameRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (customerEmail && !nameRegex.test(customerEmail.toString())) {
      setEmailValidation(false);
    }
  };

  const verifyName = () => {
    const nameRegex = /^([a-zA-Z ]){2,30}$/;
    if (customerName && !nameRegex.test(customerName.toString())) {
      setNameValidation(false);
    }
  };

  const verifyAge = () => {
    if (customerAge < 5) {
      setAgeValidation(false);
    }
  };

  const verifyMobile = () => {
    const mobileRegEx = /^[6-9]/;

    if (customerNumber && !mobileRegEx.test(customerNumber.toString())) {
      setMobileValidation(false);
    } else {
      setMobileValidation(true);
    }
  };

  useEffect(() => {
    if (verifiedOtp?.verified === true && customerNumber !== "") {
      // setScreen("form");
      dispatch(
        getBooking({
          data: { customer_mobile: customerNumber },
          key: centreData?.id,
        })
      );
    } else if (verifiedOtpEmail?.token && customerEmail !== "") {
      dispatch(
        getBooking({
          data: { customer_email: customerEmail },
          key: centreData?.id,
        })
      );
    }
  }, [verifiedOtp, verifiedOtpEmail]);
  useEffect(() => {
    if (bookingData?.booking_exists === true) {
      window.open(
        window.location.href +
          `&tid=${bookingData?.user_booking}&hid=${bookingData?.extra_detail_id}&name=${bookingData?.name}`,
        "_self"
      );
    } else if (bookingData?.booking_exists === false) {
      setScreen("form");
    }
  }, [bookingData]);

  const [disableBooking, setDisableBooking] = useState<boolean>(false);

  const submitBooking = async () => {
    setDisableBooking(true);
    const data: any = {
      booking_id: centreData?.booking,
      designation:
        customerGender === "male"
          ? "Mr"
          : customerGender === "female"
          ? "Ms"
          : "",
      customer_name: customerName,
      customer_email: customerEmail,
      customer_mobile: customerNumber,
      customer_age: customerAge,
      customer_gender: customerGender,
      packages: customerPackages,
      reference_data: employeeId,
      collection_date: moment().format("YYYY-MM-DD"),
      collection_slot: collectionSlot.id || "",
      customer_address: customerAddress,
    };
    if (!collectionSlot) {
      delete data.collection_date;
      delete data.collection_slot;
    }
    if (!customerAddress) {
      delete data.customer_address;
    }
    if (!customerEmail) {
      delete data.email;
    }
    if (!employeeId) {
      delete data.reference_data;
    }

    await dispatch(createCampBooking({ data: data, key: centreData?.id }));
  };
  useEffect(() => {
    if (disableBooking) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setDisableBooking(false);
      }, 5000);
    }
  }, [disableBooking]);
  useEffect(() => {
    if (
      healthVitalData &&
      healthVitalData.results &&
      healthVitalData.results.length > 0 &&
      testStatus?.results &&
      testStatus?.results.length > 0
    ) {
      setHeight(
        !healthVitalData.results[0].height ||
          healthVitalData.results[0].height === "0"
          ? ""
          : healthVitalData.results[0].height
      );
      setWeight(
        !healthVitalData.results[0].weight ||
          healthVitalData.results[0].weight === "0"
          ? ""
          : healthVitalData.results[0].weight
      );
      setBpHigh(
        !healthVitalData.results[0].blood_pressure_high ||
          healthVitalData.results[0].blood_pressure_high === "0"
          ? ""
          : healthVitalData.results[0].blood_pressure_high
      );
      setBpLow(
        !healthVitalData.results[0].blood_pressure_low ||
          healthVitalData.results[0].blood_pressure_low === "0"
          ? ""
          : healthVitalData.results[0].blood_pressure_low
      );
      setPulse(
        !healthVitalData.results[0].bps || healthVitalData.results[0].bps === 0
          ? ""
          : healthVitalData.results[0].bps
      );
      setHwRatio(
        !healthVitalData.results[0].hip_waist ||
          healthVitalData.results[0].hip_waist === "0"
          ? ""
          : healthVitalData.results[0].hip_waist
      );
      setBmi(
        !healthVitalData.results[0].bmi ||
          healthVitalData.results[0].bmi === "0"
          ? ""
          : healthVitalData.results[0].bmi
      );
    }
  }, [healthVitalData, testStatus]);
  const submitHealthVital = async (e: any) => {
    e.preventDefault();
    let hwRatio: any = waist && hip ? Number(waist / hip).toFixed(2) : null;
    const data: any = {
      height: height,
      weight: weight,
      blood_pressure_high: bpHigh,
      blood_pressure_low: bpLow,
      bps: pulse,
      hip_waist: hwRatio,
      bmi: bmi,
      booking: campBooking?.a_booking_id || bookingId,
    };
    if (!bpLow) {
      delete data.blood_pressure_low;
    }
    if (!bpHigh) {
      delete data.blood_pressure_high;
    }
    if (!weight) {
      delete data.weight;
    }
    if (bmi === "0.0") {
      delete data.bmi;
    }
    if (!pulse) {
      delete data.bps;
    }
    if (!height) {
      delete data.height;
    }
    await dispatch(
      updateHealthVital({
        data: data,
        key: centreData?.id,
        id: campBooking?.booking_extra_detail_id || hId,
      })
    );

    dispatch(
      updateTestStatus({
        data: { health_vital: true },
        key: centreData?.id,
        id: testStatus?.results[0]?.id,
      })
    );
    setBar(bar + barUnit);
    // dispatch(
    //   getTestStatus({
    //     url: `?booking__id=${campBooking?.a_booking_id || bookingId}`,
    //     key: centreData?.id,
    //   })
    // );

    setSelectedCard("completed");
  };
  useEffect(() => {
    let correct = "";

    if (bookingId && centreData?.id) {
      dispatch(
        getTestStatus({
          url: `?booking__id=${campBooking?.a_booking_id || bookingId}`,
          key: centreData?.id,
        })
      );
      correct = String(bookingId).slice(
        String(bookingId).length - 3,
        String(bookingId).length
      );
      setPhleboCode(correct);
      // dispatch(
      //   getBooking({
      //     url: `?booking_id=${bookingId}`,
      //     key: centreData?.id,
      //   })
      // );
    }
    createTestStatus();
  }, [campBooking, bookingId, centreData]);
  const createTestStatus = async () => {
    let data: any = {
      blood: false,
      urine: false,
      ecg: false,
      xray: false,
      pft: false,
      health_vital: false,
      audiometry: false,
      vision: false,
      doctor: false,
      booking: campBooking?.a_booking_id,
    };
    if (campBooking?.a_booking_id && centreData?.id) {
      await dispatch(
        postTestStatus({
          data: data,
          key: centreData?.id,
          campBooking: campBooking,
          customerName: customerName,
          notOpen: centreData?.payment_mode === "ONLINE" ? true : false,
        })
      );
    }
  };
  // if test status object is created then redirect to collect page
  useEffect(() => {
    if (postTestStatusData.posted) {
      if (centreData?.payment_mode === "ONLINE") {
        handlePayment(campBooking);
      } else {
        window.open(
          window.location.href +
            `&tid=${campBooking?.a_booking_id}&hid=${campBooking?.booking_extra_detail_id}&name=${customerName}`,
          "_self"
        );
      }
    }
  }, [postTestStatusData]);
  useEffect(() => {
    if (confirmBookingPay?.payment === true) {
      window.open(
        window.location.href +
          `&tid=${campBooking?.a_booking_id}&hid=${campBooking?.booking_extra_detail_id}&name=${customerName}`,
        "_self"
      );
    }
  }, [confirmBookingPay]);

  // console.log(phleboCode, "phleboCode");
  const checkPhleboCode = (type: any, code: any) => {
    setPhleboCodeInp(code);
    if (String(phleboCode) === String(code)) {
      setSelectedCard(type + "Comp");

      dispatch(
        updateTestStatus({
          data: { [type]: true },
          key: centreData?.id,
          id: testStatus?.results[0]?.id,
        })
      );
      setBar(bar + barUnit);
      // dispatch(
      //   getTestStatus({
      //     url: `?booking__id=${campBooking?.a_booking_id || bookingId}`,
      //     key: centreData?.id,
      //   })
      // );
    }
  };
  useEffect(() => {
    let num = 1;
    if (centreData?.has_ecg) {
      num = num + 1;
    }
    if (centreData?.has_xray) {
      num = num + 1;
    }
    if (centreData?.has_pft) {
      num = num + 1;
    }
    if (
      centreData?.has_bmi ||
      centreData?.bp ||
      centreData?.pulse ||
      centreData?.has_hip
    ) {
      num = num + 1;
    }
    if (centreData?.has_audiometry) {
      num = num + 1;
    }
    if (centreData?.has_urine) {
      num = num + 1;
    }
    if (centreData?.has_eye_vision) {
      num = num + 1;
    }
    if (centreData?.has_doctor_consultation) {
      num = num + 1;
    }
    if (num) {
      setBarUnit(100 / num);
    }
  }, [centreData]);
  // console.log(bar);
  useEffect(() => {
    let num = 0;
    if (verifiedCard?.blood) {
      num = num + 1;
    }
    if (verifiedCard?.ecg) {
      num = num + 1;
    }
    if (verifiedCard?.xray) {
      num = num + 1;
    }
    if (verifiedCard?.pft) {
      num = num + 1;
    }
    if (verifiedCard?.health_vital) {
      num = num + 1;
    }
    if (verifiedCard?.audiometry) {
      num = num + 1;
    }
    if (verifiedCard?.vision) {
      num = num + 1;
    }
    if (verifiedCard?.doctor) {
      num = num + 1;
    }
    if (verifiedCard?.urine) {
      num = num + 1;
    }
    if (num) {
      setBar(barUnit * num);
    }
  }, [verifiedCard]);
  useEffect(() => {
    if (hId && centreData?.id)
      dispatch(
        getHealthVital({
          url: `?id=${hId}`,
          key: centreData?.id,
        })
      );
  }, [hId, centreData]);
  useEffect(() => {
    if (bar === 100) {
      dispatch(
        smsCampCompleted({
          id: `${campBooking?.a_booking_id || bookingId}`,
          key: centreData?.id,
        })
      );
    }
  }, [bar]);
  function handleView(val: any): void {
    setOpenParam(true);
    dispatch(
      getParams({
        url: `?code=${val.code}`,
        key: partnerKey?.center_key,
      })
    );
  }
  useEffect(() => {
    if (params.status === "success" && !loading) {
      setView(params);
    }
  }, [params]);
  //slots
  const slots = useSelector((state: any) => state.webAppSlice.slots);
  const [slotType, setSlotType] = useState<any>("morning");
  const [morningSlots, setMorningSlots] = useState<any>([]);
  const [afterNoonSlots, setAfterNoonSlots] = useState<any>([]);
  const [eveningSlots, setEveningSlots] = useState<any>([]);
  const [collectionSlot, setCollectionSlot] = useState<any>("");
  // console.log(customerPackages,"customerPackages")
  useEffect(() => {
    if (partnerKey?.center_key && refData && screen === "slots") {
      dispatch(
        getSlots({
          url: `?collection_date=${moment().format(
            "YYYY-MM-DD"
          )}&ac_config_id=${refData}`,
          key: partnerKey?.center_key,
        })
      );
    }
  }, [screen, partnerKey]);
  useEffect(() => {
    if (morningSlots.length > 0) {
      setSlotType("morning");
    } else if (afterNoonSlots.length > 0) {
      setSlotType("afternoon");
    } else if (eveningSlots.length > 0) {
      setSlotType("evening");
    } else {
      setSlotType("");
    }
  }, [afterNoonSlots, morningSlots, eveningSlots]);
  useEffect(() => {
    if (slots && slots.results && slots.results.length > 0) {
      let arr: any = [];
      //time slots according to camp timing
      arr = [...slots.results].filter(
        (val: any) =>
          Number(String(val.start_time).slice(0, 2)) >=
            Number(moment(centreData?.date_of_activity_start).format("HH")) &&
          Number(String(val.start_time).slice(0, 2)) <=
            (Number(moment(centreData?.date_of_activity_end).format("HH")) < 1
              ? 25
              : Number(moment(centreData?.date_of_activity_end).format("HH")))
      );
      let m: any = [];
      let a: any = [];
      let e: any = [];
      arr.length > 0 &&
        arr?.map((val: any) => {
          if (Number(String(val.start_time).slice(0, 2)) < 12) {
            m.push(val);
          } else if (
            Number(String(val.start_time).slice(0, 2)) > 11 &&
            Number(String(val.start_time).slice(0, 2)) < 18
          ) {
            a.push(val);
          } else {
            e.push(val);
          }
        });
      setAfterNoonSlots(a);
      setMorningSlots(m);
      setEveningSlots(e);
    } else if (slots && slots.results && slots.results.length === 0) {
      setAfterNoonSlots([]);
      setMorningSlots([]);
      setEveningSlots([]);
    }
  }, [slots]);


  

  return (
    <ThemeProvider theme={theme}>
      <div className="containerMain">
        {!campValid && !loading ? (
          <div
            style={{
              width: "250px",
              color: "red",
              fontSize: 20,
              padding: 20,
              margin: "200px auto",
              borderRadius: 10,
              border: "1px solid red",
              background: "pink",
            }}
          >
            Camp is not active!!
          </div>
        ) : !campValid && loading ? (
          <Loader />
        ) : (
          <>
            <Header screen={"otp"} campLogo={centreData?.camp_logo}/>
            {screen === "otp" ? (
              <>
                {campExpired ? (
                  <Box sx={{ flexGrow: 1, marginTop: 10 }}>
                    <Card sx={{ borderRadius: "16px", m: 1 }}>
                      <Grid container spacing={2} className={"dialogContent"}>
                        <Grid item xs={12} textAlign="center">
                          <img src={expired} style={{ marginTop: 20 }} />
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                          <h3 style={{ color: "#E00646" }}>
                            Whoops, CAMP has expired!!
                          </h3>
                          <p style={{ color: "#7B7C7E90" }}>
                            Please reach out to your SPOC for more information.
                          </p>
                        </Grid>
                      </Grid>
                    </Card>
                  </Box>
                ) : (
                  <Box sx={{ flexGrow: 1, marginTop: 10 }}>
                    <Card sx={{ borderRadius: "16px", m: 1 }}>
                      <Grid container spacing={2} className="dialogContent">
                        <Grid item xs={12}>
                          <img src={lab} style={{ width: "100%" }} />
                        </Grid>
                        <Grid item xs={12}>
                          <p
                            style={{
                              fontSize: 20,
                              color: "#F50057",
                              textAlign: "center",
                            }}
                          >
                            Your first step to take charge of your health.
                          </p>
                        </Grid>

                        {centreData?.verify_via === "M" ? (
                          <Grid item xs={12}>
                            <TextField
                              key={customerNumber.length === 10 ? "0" : "1"}
                              type="number"
                              label="Mobile Number"
                              value={customerNumber}
                              variant="outlined"
                              onWheel={(e: any) => e.target.blur()}
                              onInput={(e: any) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 10);
                              }}
                              fullWidth
                              required
                              onChange={(e) =>
                                setCustomerNumber(e.target.value)
                              }
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    +91
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <sub style={{ color: "red" }}>
                              {!mobileValidation
                                ? "Please enter valid mobile number"
                                : ""}
                            </sub>
                          </Grid>
                        ) : centreData?.verify_via === "E" ? (
                          <Grid item xs={12}>
                            <TextField
                              type="email"
                              label="Email Address"
                              value={customerEmail}
                              onBlur={() => verifyEmail()}
                              onFocus={() => {
                                setEmailValidation(true);
                              }}
                              variant="outlined"
                              fullWidth
                              onChange={(e) => setCustomerEmail(e.target.value)}
                            />
                            <sub style={{ color: "red" }}>
                              {!emailValidation
                                ? "Please enter valid e-mail id"
                                : ""}
                            </sub>
                          </Grid>
                        ) : (
                          ""
                        )}

                        {sendOtp ? (
                          <>
                            <Grid item xs={12}>
                              <Typography component="p" color="green">
                                OTP has been shared on your{" "}
                                {centreData?.verify_via === "M"
                                  ? "mobile"
                                  : "Email"}
                                .{" "}
                              </Typography>
                              <Typography component="p">Enter OTP</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <OtpInput
                                value={otp}
                                isInputNum={true}
                                numInputs={6}
                                onChange={(e: any) => setOtp(e)}
                                inputStyle={{
                                  width: "1.7rem",
                                  height: "1.7rem",
                                  margin: "0 0.4rem",
                                  fontSize: "14px",
                                  borderRadius: 4,
                                  border: "1px solid rgba(0,0,0,0.3)",
                                  background: "#e7e7e7",
                                  color: "#000",
                                }}
                              />
                            </Grid>
                            {verifiedOtp?.errors ||
                            verifiedOtpEmail?.message ? (
                              <Grid item xs={12}>
                                <Typography component="p" color="red">
                                  <CancelOutlinedIcon
                                    style={{
                                      color: "red",
                                      fontSize: 18,
                                      marginBottom: "-3px",
                                    }}
                                  />{" "}
                                  Incorrect OTP!
                                </Typography>
                              </Grid>
                            ) : (
                              ""
                            )}
                            <Grid item xs={12}>
                              {seconds !== 0 ? (
                                <p>
                                  Resend OTP in
                                  <span style={{ color: "#5866E0" }}>
                                    {" "}
                                    00:{seconds < 10 ? "0" + seconds : seconds}
                                  </span>
                                </p>
                              ) : (
                                <p>
                                  <span
                                    style={{
                                      color: "#5866E0",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      proceedOtp();
                                      setSecond(30);
                                    }}
                                  >
                                    Resend OTP
                                  </span>
                                </p>
                              )}
                            </Grid>
                          </>
                        ) : (
                          <Grid item xs={12}>
                            <StyledButton
                              onClick={() => proceedOtp()}
                              disabled={
                                centreData?.verify_via === "M"
                                  ? String(customerNumber).length < 10
                                  : emailValidation === false ||
                                    customerEmail.length < 4
                              }
                            >
                              NEXT
                            </StyledButton>
                          </Grid>
                        )}
                      </Grid>
                    </Card>
                  </Box>
                )}
              </>
            ) : screen === "form" ? (
              <Box sx={{ flexGrow: 1, marginTop: 10 }}>
                <Card sx={{ borderRadius: "16px", m: 1 }}>
                  <Grid container spacing={2} className={"dialogContent"}>
                    <Grid item xs={10}>
                      <Typography component="p">YOUR DETAILS</Typography>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "right" }}>
                      {expand ? (
                        <ExpandLessIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => setExpand(false)}
                        />
                      ) : (
                        <ExpandMoreIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => setExpand(true)}
                        />
                      )}
                    </Grid>
                    {expand ? (
                      <>
                        <Grid item xs={12}>
                          <TextField
                            key={customerNumber.length === 10 ? "0" : "1"}
                            type="number"
                            label="Mobile Number"
                            value={customerNumber}
                            disabled={verifiedOtpEmail.token === undefined}
                            variant="outlined"
                            onWheel={(e: any) => e.target.blur()}
                            onInput={(e: any) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 10);
                            }}
                            fullWidth
                            onChange={(e) => setCustomerNumber(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  +91
                                </InputAdornment>
                              ),
                            }}
                          />
                          <sub style={{ color: "red" }}>
                            {!mobileValidation
                              ? "Please enter valid mobile number"
                              : ""}
                          </sub>
                        </Grid>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Card>
                <Card sx={{ borderRadius: "16px", m: 1 }}>
                  <Grid container spacing={2} className="dialogContent">
                    <Grid item xs={10}>
                      <Typography component="p">PATIENT INFORMATION</Typography>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "right" }}>
                      {expandPatient ? (
                        <ExpandLessIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => setExpandPatient(false)}
                        />
                      ) : (
                        <ExpandMoreIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => setExpandPatient(true)}
                        />
                      )}
                    </Grid>
                    {expandPatient ? (
                      <>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            color="primary"
                            className="input"
                            id="outlined-required"
                            label="Name"
                            value={customerName}
                            required
                            onBlur={() => verifyName()}
                            onFocus={() => {
                              setNameValidation(true);
                            }}
                            onChange={(e) =>
                              setCustomerName(
                                String(e.target.value).trimStart()
                              )
                            }
                          />
                          <sub style={{ color: "red" }}>
                            {!nameValidation
                              ? "Please enter valid name. Remove any special character & number. Name should have 2 to 30 alphabetical characters"
                              : ""}
                          </sub>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Employee ID"
                            fullWidth
                            onChange={(e) => setEmployeeId(e.target.value)}
                            value={employeeId}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            type="email"
                            label="Email Address"
                            value={customerEmail}
                            onBlur={() => verifyEmail()}
                            onFocus={() => {
                              setEmailValidation(true);
                            }}
                            variant="outlined"
                            fullWidth
                            disabled={verifiedOtpEmail.token}
                            onChange={(e) => setCustomerEmail(e.target.value)}
                          />
                          <sub style={{ color: "red" }}>
                            {!emailValidation
                              ? "Please enter valid e-mail id"
                              : ""}
                          </sub>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            color="primary"
                            className="input"
                            id="outlined-required"
                            label="Age"
                            type="number"
                            value={customerAge}
                            onBlur={() => verifyAge()}
                            onFocus={() => {
                              setAgeValidation(true);
                            }}
                            onKeyDown={(e) => blockInvalidChar(e)}
                            variant="outlined"
                            required
                            onChange={(e) =>
                              Number(e.target.value) < 110
                                ? setCustomerAge(e.target.value)
                                : ""
                            }
                          />
                          <sub style={{ color: "red" }}>
                            {!ageValidation
                              ? "Please enter valid age, age should be 4+ Years"
                              : ""}
                          </sub>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">
                              Gender
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue="male"
                              value={customerGender}
                              name="radio-buttons-group"
                              onChange={(e) =>
                                setCustomerGender(e.target.value)
                              }
                            >
                              <FormControlLabel
                                value="female"
                                control={<Radio />}
                                label="Female"
                              />
                              <FormControlLabel
                                value="male"
                                control={<Radio />}
                                label="Male"
                              />
                              {/* <FormControlLabel
                                value="other"
                                control={<Radio />}
                                label="Other"
                              /> */}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">
                              Fasting
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue="male"
                              value={fastingRequired}
                              name="radio-buttons-group"
                              onChange={(e) =>
                                setFastingRequired(e.target.value)
                              }
                            >
                              <FormControlLabel
                                value="yes"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="no"
                                control={<Radio />}
                                label="No"
                              />
                              {/* <FormControlLabel
                                value="other"
                                control={<Radio />}
                                label="Other"
                              /> */}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Card>
                {centreData?.locations?.length > 1 ? (
                  <Card sx={{ borderRadius: "16px", m: 1 }}>
                    <Grid container spacing={2} className={"dialogContent"}>
                      <Grid item xs={12}>
                        <Typography component="p">
                          SELECT CAMP LOCATION
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ overflowX: "auto", overflowY: "hidden" }}
                      >
                        <div style={{ display: "inline-flex" }}>
                          {centreData?.locations.map((loc: any) => (
                            <div
                              style={{
                                padding: 10,
                                borderRadius: 8,
                                border:
                                  customerAddress === loc.address
                                    ? "1px solid #e00546"
                                    : "1px solid #B3B3B3",
                                background:
                                  customerAddress === loc.address
                                    ? "#e0054626"
                                    : "#fff",
                                width: 140,
                                marginRight: 5,
                              }}
                              onClick={() => setCustomerAddress(loc.address)}
                            >
                              <b>{loc.name}</b>
                              <br />
                              <small>Address: {loc.address}</small>
                            </div>
                          ))}
                        </div>
                      </Grid>
                    </Grid>
                  </Card>
                ) : (
                  ""
                )}
                {centreData.multi_package ? (
                  <div style={{ margin: "0 15px 15px" }}>
                    <StyledButton
                      onClick={() => setScreen("packages")}
                      disabled={
                        !mobileValidation ||
                        !nameValidation ||
                        !ageValidation ||
                        customerGender === "" ||
                        !customerName ||
                        disableBooking ||
                        !fastingRequired ||
                        (centreData?.locations?.length > 1 && !customerAddress)
                      }
                    >
                      NEXT
                    </StyledButton>
                  </div>
                ) : centreData.in_each_slot ? (
                  <div style={{ margin: "0 15px 15px" }}>
                    <StyledButton
                      onClick={() => setScreen("slots")}
                      disabled={
                        !mobileValidation ||
                        !nameValidation ||
                        !ageValidation ||
                        customerGender === "" ||
                        !customerName ||
                        customerPackages.length === 0 ||
                        disableBooking ||
                        !fastingRequired ||
                        (centreData?.locations?.length > 1 && !customerAddress)
                      }
                    >
                      NEXT
                    </StyledButton>
                  </div>
                ) : (
                  <div style={{ margin: "0 15px 15px" }}>
                    <StyledButton
                      onClick={() => {
                        campBooking.booking_price
                          ? handlePayment(campBooking)
                          : submitBooking();
                      }}
                      disabled={
                        !mobileValidation ||
                        !nameValidation ||
                        !ageValidation ||
                        customerGender === "" ||
                        !customerName ||
                        customerPackages.length === 0 ||
                        disableBooking ||
                        !fastingRequired ||
                        (centreData?.locations?.length > 1 && !customerAddress)
                      }
                    >
                     {centreData?.payment_mode === "ONLINE"?"PROCEED TO PAY":"CONFIRM REGISTRATION"}  
                    </StyledButton>
                    {customerPackages.length === 0 &&
                    customerGender !== "" &&
                    customerAge !== "" ? (
                      <sub style={{ color: "red" }}>
                        No package is configure for mentioned details.
                      </sub>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </Box>
            ) : screen === "packages" ? (
              <Box sx={{ flexGrow: 1, marginTop: 10 }}>
                <Card sx={{ borderRadius: "16px", m: 1 }}>
                  <Grid container spacing={2} className="dialogContent">
                    <Grid item xs={10}>
                      <Typography component="p">SELECT PACKAGE</Typography>
                      <hr />
                    </Grid>
                    <Grid item xs={12}>
                      {packages?.data &&
                        packages?.data.length > 0 &&
                        packages.data.map((val: any, index: any) => {
                          return (
                            <Grid
                              style={{
                                width: "100%",
                                padding: "0px 13px 0px 13px",
                              }}
                              key={val.id}
                            >
                              <div style={{ display: "flex", width: "100%" }}>
                                <div
                                  style={{ width: "10%", textAlign: "left" }}
                                >
                                  <Checkbox
                                    color="primary"
                                    style={{ padding: 0 }}
                                    checked={
                                      customerPackages.length > 0 &&
                                      customerPackages[0] === val.id
                                        ? true
                                        : false
                                    }
                                    // disabled={
                                    //   val.recommended_gender !==
                                    //     customerGender &&
                                    //   val.recommended_gender !== "both"
                                    // }
                                    onChange={(e) => {
                                      setCustomerPackages([val.id]);
                                    }}
                                  />
                                </div>
                                <div style={{ width: "70%" }}>
                                  <p style={{ fontSize: "18px", margin: 0 }}>
                                    {val?.name}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    textAlign: "right",
                                    width: "20%",
                                    fontWeight: "bold",
                                  }}
                                >
                                  ₹
                                  {val?.package_center_prices?.city_offer_price
                                    ? val?.package_center_prices
                                        ?.city_offer_price
                                    : val?.package_center_prices?.offer_price}
                                  /-
                                  <br />
                                  <span
                                    style={{
                                      textDecoration: "line-through",
                                      fontSize: "12px",
                                      fontWeight: "400",
                                      color: "gray",
                                      margin: "0px 0px 0px -15px",
                                    }}
                                  >
                                    MRP: ₹
                                    {val?.package_center_prices?.package_price}
                                    /-
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "#A2A2A2",
                                  margin: "0 20% 0 10%",
                                }}
                              >
                                <span>
                                  includes: {val.parameter || 0} parameters
                                </span>
                                <br />
                                {val.description &&
                                  val.description.split(",").length > 0 &&
                                  String(
                                    val.description
                                      .split(",")
                                      .map((data: any, index: any) => {
                                        return index < 2 ? `${data},` : "";
                                      })
                                  ).substring(0, 30)}
                                {val?.parameter > 1 ? (
                                  <a
                                    style={{
                                      color: "blue",
                                      cursor: "pointer",
                                      marginLeft: 5,
                                    }}
                                    onClick={() => handleView(val)}
                                  >
                                    view more
                                  </a>
                                ) : (
                                  ""
                                )}
                                {val?.fasting_time ? (
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      color: "grey",
                                      display: "flex",
                                      marginTop: 5,
                                    }}
                                  >
                                    <div style={{ width: "8%" }}>
                                      <CheckCircleIcon
                                        style={{ fontSize: "15px" }}
                                      />
                                    </div>

                                    <div
                                      style={{ width: "92%", fontSize: "12px" }}
                                    >
                                      {val?.fasting_time}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {val?.tat_time ? (
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      color: "grey",
                                      display: "flex",
                                    }}
                                  >
                                    <div style={{ width: "8%" }}>
                                      <CheckCircleIcon
                                        style={{ fontSize: "15px" }}
                                      />
                                    </div>

                                    <div
                                      style={{ width: "92%", fontSize: "12px" }}
                                    >
                                      Within {val?.tat_time}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <hr />
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Card>

                <div style={{ margin: "0 15px 15px" }}>
                  {centreData?.in_each_slot ? (
                    <StyledButton
                      onClick={() => setScreen("slots")}
                      disabled={
                        !mobileValidation ||
                        !nameValidation ||
                        !ageValidation ||
                        customerGender === "" ||
                        !customerName ||
                        customerPackages.length === 0 ||
                        disableBooking ||
                        !fastingRequired
                      }
                    >
                      NEXT
                    </StyledButton>
                  ) : (
                    <StyledButton
                      onClick={() => {
                        campBooking.booking_price
                          ? handlePayment(campBooking)
                          : submitBooking();
                      }}
                      disabled={
                        !mobileValidation ||
                        !nameValidation ||
                        !ageValidation ||
                        customerGender === "" ||
                        !customerName ||
                        customerPackages.length === 0 ||
                        disableBooking ||
                        !fastingRequired
                      }
                    >
                       {centreData?.payment_mode === "ONLINE"?"PROCEED TO PAY":"CONFIRM REGISTRATION"}  
                    </StyledButton>
                  )}
                </div>
              </Box>
            ) : screen === "slots" ? (
              <Box sx={{ flexGrow: 1, marginTop: 10 }}>
                <Card sx={{ borderRadius: "16px", m: 1 }}>
                  <Grid container spacing={2} className="dialogContent">
                    <Grid item xs={12}>
                      <Typography component="p">SELECT TIME SLOT</Typography>
                    </Grid>
                    {loading ? (
                      <Loader />
                    ) : (
                      <>
                        <Grid container className="borderBox">
                          <Grid item xs={10}>
                            <img src={morning} /> Morning
                          </Grid>
                          <Grid item xs={2} textAlign="right">
                            {slotType === "morning" ? (
                              <ExpandLessOutlinedIcon
                                onClick={() => setSlotType("")}
                              />
                            ) : (
                              <ExpandMoreOutlinedIcon
                                onClick={() => setSlotType("morning")}
                              />
                            )}
                          </Grid>
                          {slotType === "morning" ? (
                            <Grid container>
                              {morningSlots.length > 0 &&
                                morningSlots?.map((val: any) => (
                                  <Grid item xs={6} textAlign="center">
                                    <Button
                                      variant={
                                        collectionSlot?.id === val.id
                                          ? "contained"
                                          : "outlined"
                                      }
                                      size="small"
                                      color="primary"
                                      onClick={() => setCollectionSlot(val)}
                                      style={{ display: "block" }}
                                    >
                                      <div>
                                        {val?.start_time} - {val?.end_time}
                                      </div>
                                      <div style={{ fontSize: 10 }}>
                                        Remaining slots: {val?.available_slots}
                                      </div>
                                    </Button>
                                  </Grid>
                                ))}
                            </Grid>
                          ) : (
                            ""
                          )}
                        </Grid>

                        <Grid container className="borderBox">
                          <Grid item xs={10}>
                            <img src={afternoon} /> Afternoon
                          </Grid>
                          <Grid item xs={2} textAlign="right">
                            {slotType === "afternoon" ? (
                              <ExpandLessOutlinedIcon
                                onClick={() => setSlotType("")}
                              />
                            ) : (
                              <ExpandMoreOutlinedIcon
                                onClick={() => setSlotType("afternoon")}
                              />
                            )}
                          </Grid>
                          {slotType === "afternoon" ? (
                            <Grid container>
                              {afterNoonSlots.length > 0 &&
                                afterNoonSlots?.map((val: any) => (
                                  <Grid item xs={6} textAlign="center">
                                    <Button
                                      variant={
                                        collectionSlot?.id === val.id
                                          ? "contained"
                                          : "outlined"
                                      }
                                      size="small"
                                      color="primary"
                                      onClick={() => setCollectionSlot(val)}
                                      style={{ display: "block" }}
                                    >
                                      <div>
                                        {val?.start_time} - {val?.end_time}
                                      </div>
                                      <div style={{ fontSize: 10 }}>
                                        Remaining slots: {val?.available_slots}
                                      </div>
                                    </Button>
                                  </Grid>
                                ))}
                            </Grid>
                          ) : (
                            ""
                          )}
                        </Grid>

                        <Grid container className="borderBox">
                          <Grid item xs={10}>
                            <img src={evening} /> Evening
                          </Grid>
                          <Grid item xs={2} textAlign="right">
                            {slotType === "evening" ? (
                              <ExpandLessOutlinedIcon
                                onClick={() => setSlotType("")}
                              />
                            ) : (
                              <ExpandMoreOutlinedIcon
                                onClick={() => setSlotType("evening")}
                              />
                            )}
                          </Grid>
                          {slotType === "evening" ? (
                            <Grid container>
                              {eveningSlots.length > 0 &&
                                eveningSlots?.map((val: any) => (
                                  <Grid item xs={6} textAlign="center">
                                    <Button
                                      variant={
                                        collectionSlot?.id === val.id
                                          ? "contained"
                                          : "outlined"
                                      }
                                      size="small"
                                      color="primary"
                                      onClick={() => setCollectionSlot(val)}
                                      style={{ display: "block" }}
                                    >
                                      <div>
                                        {val?.start_time} - {val?.end_time}
                                      </div>
                                      <div style={{ fontSize: 10 }}>
                                        Remaining slots: {val?.available_slots}
                                      </div>
                                    </Button>
                                  </Grid>
                                ))}
                            </Grid>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Card>

                <div style={{ margin: "0 15px 15px" }}>
                  <StyledButton
                    onClick={() => {
                      campBooking.booking_price
                        ? handlePayment(campBooking)
                        : submitBooking();
                    }}
                    disabled={
                      !mobileValidation ||
                      !nameValidation ||
                      !ageValidation ||
                      customerGender === "" ||
                      !customerName ||
                      customerPackages.length === 0 ||
                      disableBooking ||
                      !fastingRequired ||
                      !collectionSlot
                    }
                  >
                   {centreData?.payment_mode === "ONLINE"?"PROCEED TO PAY":"CONFIRM REGISTRATION"}  
                  </StyledButton>
                </div>
              </Box>
            ) : screen === "confirmation" ? (
              <>
                {campStarted ? (
                  <Box sx={{ flexGrow: 1, marginTop: 10 }}>
                    <Card sx={{ borderRadius: "16px", m: 1 }}>
                      <Grid container spacing={2} className={"dialogContent"}>
                        <Grid item xs={12} textAlign="center">
                          <span style={{ color: "#F50057" }}>
                            ID: {campBooking?.a_booking_id || bookingId}
                          </span>
                          <br />
                          <span>Patient Name: {customerName}</span>
                        </Grid>
                      </Grid>
                    </Card>
                    <Card sx={{ borderRadius: "16px", m: 1 }}>
                      <Grid container spacing={2} className={"dialogContent"}>
                        <Grid item xs={12} textAlign="center">
                          <span style={{ color: "#F50057", fontSize: 12 }}>
                            Note: Phlebotomist will fill all of your important
                            health vital details.
                          </span>
                        </Grid>
                      </Grid>
                    </Card>
                    <Grid container className={"dialogContent"}>
                      <Grid item xs={12} textAlign="center">
                        <div
                          style={{
                            marginBottom: "-20px",
                            zIndex: "1",
                            position: "relative",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              fontStyle: "italic",
                              color: "white",
                            }}
                          >
                            {Number(bar).toFixed()}% COMPLETED
                          </span>
                        </div>
                        <LinearProgress
                          color="success"
                          variant="determinate"
                          value={bar}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        textAlign="center"
                        style={{ position: "relative" }}
                      >
                        <Card
                          sx={{ borderRadius: "16px", p: 1, cursor: "pointer" }}
                          onClick={() => {
                            if (!verifiedCard.blood) {
                              setSelectedCard("blood");
                              setOpenPhleboCode(true);
                            }
                          }}
                        >
                          <img src={blood} />
                          <div>Blood</div>
                          <sub>
                            {verifiedCard.blood ? (
                              <span style={{ color: "green" }}>Confirmed</span>
                            ) : (
                              <span style={{ color: "red" }}>Pending</span>
                            )}
                          </sub>
                        </Card>
                        <div className="timeTaken">
                          <ScheduleRoundedIcon />
                          <div style={{ lineHeight: "10px" }}>
                            <div style={{ fontSize: "14px" }}>15</div>{" "}
                            <span>MIN</span>
                          </div>
                        </div>
                      </Grid>
                      {centreData?.has_urine && (
                        <Grid
                          item
                          xs={6}
                          textAlign="center"
                          style={{ position: "relative" }}
                        >
                          <Card
                            sx={{
                              borderRadius: "16px",
                              p: 1,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (!verifiedCard.urine) {
                                setSelectedCard("urine");
                                setOpenPhleboCode(true);
                              }
                            }}
                          >
                            <img src={blood} />
                            <div>Urine</div>
                            <sub>
                              {verifiedCard.urine ? (
                                <span style={{ color: "green" }}>
                                  Confirmed
                                </span>
                              ) : (
                                <span style={{ color: "red" }}>Pending</span>
                              )}
                            </sub>
                          </Card>
                          <div className="timeTaken">
                            <ScheduleRoundedIcon />
                            <div style={{ lineHeight: "10px" }}>
                              <div style={{ fontSize: "14px" }}>15</div>{" "}
                              <span>MIN</span>
                            </div>
                          </div>
                        </Grid>
                      )}

                      {centreData?.has_ecg && (
                        <Grid
                          item
                          xs={6}
                          textAlign="center"
                          style={{ position: "relative" }}
                        >
                          <Card
                            sx={{
                              borderRadius: "16px",
                              p: 1,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (!verifiedCard.ecg) {
                                setSelectedCard("ecg");
                                setOpenPhleboCode(true);
                              }
                            }}
                          >
                            <img src={ecg} />
                            <div>ECG</div>
                            <sub>
                              {verifiedCard.ecg ? (
                                <span style={{ color: "green" }}>
                                  Confirmed
                                </span>
                              ) : (
                                <span style={{ color: "red" }}>Pending</span>
                              )}
                            </sub>
                          </Card>
                          <div className="timeTaken">
                            <ScheduleRoundedIcon />
                            <div style={{ lineHeight: "10px" }}>
                              <div style={{ fontSize: "14px" }}>15</div>{" "}
                              <span>MIN</span>
                            </div>
                          </div>
                        </Grid>
                      )}

                      {centreData?.has_xray && (
                        <Grid
                          item
                          xs={6}
                          textAlign="center"
                          style={{ position: "relative" }}
                        >
                          <Card
                            sx={{
                              borderRadius: "16px",
                              p: 1,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (!verifiedCard.xray) {
                                setSelectedCard("xray");
                                setOpenPhleboCode(true);
                              }
                            }}
                          >
                            <img src={xray} />
                            <div>X-Ray</div>
                            <sub>
                              {verifiedCard.xray ? (
                                <span style={{ color: "green" }}>
                                  Confirmed
                                </span>
                              ) : (
                                <span style={{ color: "red" }}>Pending</span>
                              )}
                            </sub>
                          </Card>
                          <div className="timeTaken">
                            <ScheduleRoundedIcon />
                            <div style={{ lineHeight: "10px" }}>
                              <div style={{ fontSize: "14px" }}>15</div>{" "}
                              <span>MIN</span>
                            </div>
                          </div>
                        </Grid>
                      )}

                      {centreData?.has_pft && (
                        <Grid
                          item
                          xs={6}
                          textAlign="center"
                          style={{ position: "relative" }}
                        >
                          <Card
                            sx={{
                              borderRadius: "16px",
                              p: 1,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (!verifiedCard.pft) {
                                setSelectedCard("pft");
                                setOpenPhleboCode(true);
                              }
                            }}
                          >
                            <img src={pftImg} />
                            <div>PFT</div>
                            <sub>
                              {verifiedCard.pft ? (
                                <span style={{ color: "green" }}>
                                  Confirmed
                                </span>
                              ) : (
                                <span style={{ color: "red" }}>Pending</span>
                              )}
                            </sub>
                          </Card>
                          <div className="timeTaken">
                            <ScheduleRoundedIcon />
                            <div style={{ lineHeight: "10px" }}>
                              <div style={{ fontSize: "14px" }}>15</div>{" "}
                              <span>MIN</span>
                            </div>
                          </div>
                        </Grid>
                      )}

                      {centreData?.has_audiometry && (
                        <Grid
                          item
                          xs={6}
                          textAlign="center"
                          style={{ position: "relative" }}
                        >
                          <Card
                            sx={{
                              borderRadius: "16px",
                              p: 1,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (!verifiedCard.audiometry) {
                                setSelectedCard("audiometry");
                                setOpenPhleboCode(true);
                              }
                            }}
                          >
                            <img src={audiometryImg} width="50px" />
                            <div>Audiometry</div>
                            <sub>
                              {verifiedCard.audiometry ? (
                                <span style={{ color: "green" }}>
                                  Confirmed
                                </span>
                              ) : (
                                <span style={{ color: "red" }}>Pending</span>
                              )}
                            </sub>
                          </Card>
                          <div className="timeTaken">
                            <ScheduleRoundedIcon />
                            <div style={{ lineHeight: "10px" }}>
                              <div style={{ fontSize: "14px" }}>15</div>{" "}
                              <span>MIN</span>
                            </div>
                          </div>
                        </Grid>
                      )}

                      {centreData?.has_eye_vision && (
                        <Grid
                          item
                          xs={6}
                          textAlign="center"
                          style={{ position: "relative" }}
                        >
                          <Card
                            sx={{
                              borderRadius: "16px",
                              p: 1,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (!verifiedCard.vision) {
                                setSelectedCard("vision");
                                setOpenPhleboCode(true);
                              }
                            }}
                          >
                            <img src={visionImg} width="50px" />
                            <div>Vision Check Up</div>
                            <sub>
                              {verifiedCard.vision ? (
                                <span style={{ color: "green" }}>
                                  Confirmed
                                </span>
                              ) : (
                                <span style={{ color: "red" }}>Pending</span>
                              )}
                            </sub>
                          </Card>
                          <div className="timeTaken">
                            <ScheduleRoundedIcon />
                            <div style={{ lineHeight: "10px" }}>
                              <div style={{ fontSize: "14px" }}>15</div>{" "}
                              <span>MIN</span>
                            </div>
                          </div>
                        </Grid>
                      )}

                      {centreData?.has_doctor_consultation && (
                        <Grid
                          item
                          xs={6}
                          textAlign="center"
                          style={{ position: "relative" }}
                        >
                          <Card
                            sx={{
                              borderRadius: "16px",
                              p: 1,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (!verifiedCard.doctor) {
                                setSelectedCard("doctor");
                                setOpenPhleboCode(true);
                              }
                            }}
                          >
                            <img src={doctorImg} width="50px" />
                            <div>Doctor Consultation</div>
                            <sub>
                              {verifiedCard.doctor ? (
                                <span style={{ color: "green" }}>
                                  Confirmed
                                </span>
                              ) : (
                                <span style={{ color: "red" }}>Pending</span>
                              )}
                            </sub>
                          </Card>
                          <div className="timeTaken">
                            <ScheduleRoundedIcon />
                            <div style={{ lineHeight: "10px" }}>
                              <div style={{ fontSize: "14px" }}>15</div>{" "}
                              <span>MIN</span>
                            </div>
                          </div>
                        </Grid>
                      )}
                      {centreData?.has_bmi ||
                      centreData?.bp ||
                      centreData?.pulse ||
                      centreData?.has_hip ? (
                        <Grid
                          item
                          xs={6}
                          textAlign="center"
                          style={{ position: "relative" }}
                        >
                          <Card
                            sx={{
                              borderRadius: "16px",
                              p: 1,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSelectedCard("health_vital");
                              setOpenPhleboCode(true);
                            }}
                          >
                            <img src={healthVital} />
                            <div>Health Vitals</div>
                            <sub>
                              {verifiedCard.health_vital ? (
                                <span style={{ color: "green" }}>
                                  Confirmed
                                </span>
                              ) : (
                                <span style={{ color: "red" }}>Pending</span>
                              )}
                            </sub>
                          </Card>
                          <div className="timeTaken">
                            <ScheduleRoundedIcon />
                            <div style={{ lineHeight: "10px" }}>
                              <div style={{ fontSize: "14px" }}>5</div>{" "}
                              <span>MIN</span>
                            </div>
                          </div>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Box>
                ) : campExpired ? (
                  <Box sx={{ flexGrow: 1, marginTop: 10 }}>
                    <Card sx={{ borderRadius: "16px", m: 1 }}>
                      <Grid container spacing={2} className={"dialogContent"}>
                        <Grid item xs={12} textAlign="center">
                          <img src={expired} style={{ marginTop: 20 }} />
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                          <h3 style={{ color: "#E00646" }}>
                            Whoops, CAMP has expired!!
                          </h3>
                          <p style={{ color: "#7B7C7E90" }}>
                            Please reach out to your SPOC for more information.
                          </p>
                        </Grid>
                      </Grid>
                    </Card>
                  </Box>
                ) : (
                  <>
                    <div
                      style={{
                        background: "#11406B",
                        textAlign: "center",
                        color: "white",
                        padding: "15px",
                        marginTop: 80,
                      }}
                    >
                      <p style={{ fontSize: 20, fontWeight: "bolder" }}>
                        Getting Started Soon
                      </p>
                      <div
                        style={{
                          margin: "0px 30%",
                          borderRadius: 5,
                          background: "#ffffff50",
                        }}
                      >
                        <span>
                          Starting on{" "}
                          {moment(centreData?.date_of_activity_start).format(
                            "DD-MM-YY, HH:mm"
                          )}
                        </span>
                      </div>
                    </div>
                    <Box sx={{ flexGrow: 1 }}>
                      <Card sx={{ borderRadius: "16px", m: 1 }}>
                        <Grid container spacing={2} className={"dialogContent"}>
                          <Grid item xs={2} textAlign="center">
                            <img src={verified} style={{ marginTop: 20 }} />
                          </Grid>
                          <Grid item xs={10}>
                            <Typography component="p">
                              Thank you for registering. Please keep this ID -{" "}
                              {bookingId}
                              handy as this will help you you complete your camp
                              process
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                      <Card sx={{ borderRadius: "16px", m: 1 }}>
                        <Grid container spacing={2} className={"dialogContent"}>
                          <Grid item xs={12}>
                            <img src={labtest} style={{ width: "100%" }} />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography component="p" textAlign={"center"}>
                              CAMP will start on{" "}
                              {moment(
                                centreData?.date_of_activity_start
                              ).format("DD-MM-YY, HH:mm")}{" "}
                              and end on{" "}
                              {moment(centreData?.date_of_activity_end).format(
                                "DD-MM-YY, HH:mm"
                              )}
                              . Please visit again through the link shared over
                              SMS
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    </Box>
                  </>
                )}
              </>
            ) : screen === "loading" ? (
              <div style={{ textAlign: "center", marginTop: 100 }}>
                <Loader />
                <b>Confirming...</b>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
        {/* phlebo code verification */}
        <Dialog
          open={openPhleboCode}
          onClose={() =>
            // selectedCard === "completed" ? "" : setOpenPhleboCode(false)
            {
              setOpenPhleboCode(false);
              setPhleboCodeInp("");
            }
          }
        >
          <div style={{ padding: 20, maxWidth: 450 }}>
            {selectedCard === "blood" ||
            selectedCard === "urine" ||
            selectedCard === "ecg" ||
            selectedCard === "xray" ||
            selectedCard === "pft" ||
            selectedCard === "audiometry" ||
            selectedCard === "vision" ||
            selectedCard === "doctor" ? (
              <>
                <p>Health technician to enter the code below:</p>
                <TextField
                  fullWidth
                  variant={"outlined"}
                  value={phleboCodeInp}
                  onChange={(e) =>
                    checkPhleboCode(selectedCard, e.target.value)
                  }
                />
                {phleboCodeInp.length > 2 && phleboCodeInp !== phleboCode ? (
                  <sub style={{ color: "red" }}>
                    Invalid!! Please enter correct code.
                  </sub>
                ) : (
                  ""
                )}
                <p style={{ color: "#F50057" }}>
                  *Note: Once your blood sample collection is completed, please
                  ask the health technician to enter the code to proceed
                  further.
                </p>
              </>
            ) : selectedCard === "bloodComp" ||
              selectedCard === "urineComp" ||
              selectedCard === "ecgComp" ||
              selectedCard === "xrayComp" ||
              selectedCard === "pftComp" ||
              selectedCard === "audiometryComp" ||
              selectedCard === "visionComp" ||
              selectedCard === "doctorComp" ? (
              <div style={{ textAlign: "center" }}>
                <img src={confirm} />
                <p>
                  {selectedCard === "bloodComp"
                    ? "Blood sample collection"
                    : selectedCard === "urineComp"
                    ? "Urine sample collected"
                    : selectedCard === "ecgComp"
                    ? "ECG"
                    : selectedCard === "xrayComp"
                    ? "X-Ray"
                    : selectedCard === "pftComp"
                    ? "PFT"
                    : selectedCard === "audiometryComp"
                    ? "Audiometry"
                    : selectedCard === "visionComp"
                    ? "Vision checkup"
                    : selectedCard === "doctorComp"
                    ? "Doctor consultation"
                    : ""}{" "}
                  has been completed. Please proceed to the next booth.
                </p>
              </div>
            ) : selectedCard === "completed" ? (
              <div style={{ textAlign: "center" }}>
                <img src={completed} />
                <h4>Tests Successful</h4>
                <p>
                  Thank you for completing all your tests. Your reports will be
                  shared shortly.
                </p>
              </div>
            ) : (
              <form onSubmit={submitHealthVital}>
                <Grid container spacing={1}>
                  <Grid item xs={12} textAlign="center">
                    <h2>Health Vital Details</h2>
                  </Grid>
                  {centreData.has_bmi && (
                    <>
                      <Grid item xs={2}>
                        <img
                          src={heightimg}
                          style={{ height: "30px", marginTop: 5 }}
                        />
                      </Grid>
                      <Grid item xs={4} style={{ marginTop: 8 }}>
                        Height
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          type="number"
                          required
                          disabled={verifiedCard.health_vital}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                cm
                              </InputAdornment>
                            ),
                          }}
                          value={height}
                          onChange={(e) => setHeight(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <img
                          src={weightimg}
                          style={{ height: "30px", marginTop: 5 }}
                        />
                      </Grid>
                      <Grid item xs={4} style={{ marginTop: 8 }}>
                        Weight
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          required
                          disabled={verifiedCard.health_vital}
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                kg
                              </InputAdornment>
                            ),
                          }}
                          value={weight}
                          onChange={(e) => setWeight(e.target.value)}
                        />
                      </Grid>
                    </>
                  )}
                  {centreData?.has_bp && (
                    <>
                      <Grid item xs={2}>
                        <img
                          src={bpimg}
                          style={{ height: "30px", marginTop: 5 }}
                        />
                      </Grid>
                      <Grid item xs={4} style={{ marginTop: 8 }}>
                        Blood Pressure
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          size="small"
                          type="number"
                          disabled={verifiedCard.health_vital}
                          required
                          label="Systolic"
                          value={bpHigh}
                          onChange={(e) => setBpHigh(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          size="small"
                          type="number"
                          disabled={verifiedCard.health_vital}
                          required
                          label="Diastolic"
                          value={bpLow}
                          onChange={(e) => setBpLow(e.target.value)}
                        />
                      </Grid>
                    </>
                  )}

                  {centreData?.has_pulse && (
                    <>
                      <Grid item xs={2}>
                        <img
                          src={pulseimg}
                          style={{ height: "30px", marginTop: 5 }}
                        />
                      </Grid>
                      <Grid item xs={4} style={{ marginTop: 8 }}>
                        Pulse
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          type="number"
                          disabled={verifiedCard.health_vital}
                          required
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                bpm
                              </InputAdornment>
                            ),
                          }}
                          value={pulse}
                          onChange={(e) => setPulse(e.target.value)}
                        />
                      </Grid>
                    </>
                  )}
                  {centreData?.has_hip && (
                    <>
                      <Grid item xs={2}>
                        <img
                          src={hwratioimg}
                          style={{ height: "30px", marginTop: 5 }}
                        />
                      </Grid>
                      <Grid item xs={4} style={{ marginTop: 8 }}>
                        Waist to Hip Ratio
                      </Grid>
                      {verifiedCard.health_vital ? (
                        ""
                      ) : (
                        <>
                          <Grid item xs={3}>
                            <TextField
                              size="small"
                              type="number"
                              required
                              label="Waist(cm)"
                              value={waist}
                              onChange={(e) => setWaist(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <TextField
                              size="small"
                              type="number"
                              required
                              label="Hip(cm)"
                              value={hip}
                              onChange={(e) => setHip(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={6}></Grid>
                        </>
                      )}

                      <Grid item xs={6}>
                        <TextField
                          label="Waist to Hip Ratio"
                          size="small"
                          required
                          disabled={verifiedCard.health_vital}
                          fullWidth
                          value={
                            waist && hip
                              ? Number(waist / hip).toFixed(2)
                              : hwRatio
                          }
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    {height && weight && (
                      <div
                        style={{
                          background: "rgba(245, 0, 87, 0.1)",
                          mixBlendMode: "darken",
                          borderRadius: 6,
                          textAlign: "center",
                          padding: 5,
                        }}
                      >
                        <h4>
                          Your BMI is{" "}
                          <span style={{ color: "#F50057" }}>
                            {bmi}
                            <br />

                            <span
                              style={{
                                color:
                                  bmi < 18.5
                                    ? "red"
                                    : bmi > 18.5 && bmi < 24.9
                                    ? "green"
                                    : bmi > 25 && bmi < 29.9
                                    ? "orange"
                                    : "red",
                              }}
                            >
                              {" "}
                              {bmi < 18.5
                                ? "Underweight"
                                : bmi > 18.5 && bmi < 24.9
                                ? "Normal weight"
                                : bmi > 25 && bmi < 29.9
                                ? "Overweight"
                                : "Obese"}
                            </span>
                          </span>
                        </h4>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {verifiedCard.health_vital ? (
                      ""
                    ) : (
                      <StyledButton type="submit">Submit</StyledButton>
                    )}
                  </Grid>
                </Grid>
              </form>
            )}
          </div>
        </Dialog>
        <Dialog
          disableEscapeKeyDown={true}
          open={openParam}
          // onClose={() => setOpenParam(false)}
          aria-describedby="alert-dialog-slide-description"
          className="dialogBottom"
        >
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={11}>
              <Typography
                component="p"
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#E06358",
                }}
              >
                {view && view?.name}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Tooltip title="Close">
                <CloseIcon onClick={() => setOpenParam(false)} />
              </Tooltip>
            </Grid>
            {loading ? (
              <Grid item xs={12} textAlign={"center"}>
                <Loader />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                style={{ overflow: "auto", maxHeight: "80vh" }}
              >
                {view?.data &&
                  view?.data.length > 0 &&
                  view.data.map((data: any) => {
                    return (
                      <Grid container>
                        <Grid item xs={10}>
                          {data.name}
                          <span style={{ color: "#7B7C7E" }}>
                            (
                            {`${
                              data?.package_detail?.length > 0
                                ? data.package_detail.length + " tests"
                                : "1" + " test"
                            }`}
                            )
                          </span>
                        </Grid>
                        <Grid item xs={2} textAlign="right">
                          {isSelected === data.name ? (
                            <ExpandLessOutlinedIcon
                              onClick={() => setIsSelected("")}
                            />
                          ) : (
                            <ExpandMoreOutlinedIcon
                              onClick={() => setIsSelected(data.name)}
                            />
                          )}
                        </Grid>
                        {isSelected === data.name ? (
                          <Grid
                            item
                            xs={12}
                            style={{ overflow: "auto", maxHeight: "400px" }}
                          >
                            <ol
                              style={{
                                color: "#7B7C7E",
                                fontSize: "14px",
                                lineHeight: "20px",
                              }}
                            >
                              {data?.package_detail &&
                                data?.package_detail.length > 0 &&
                                data?.package_detail.map((val: any) => {
                                  return <li>{val?.name}</li>;
                                })}
                              {data?.package_detail &&
                                data?.package_detail.length === 0 && (
                                  <li>{data.name}</li>
                                )}
                            </ol>
                          </Grid>
                        ) : (
                          ""
                        )}
                        <Grid item xs={12}>
                          {" "}
                          <hr />
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
            )}
          </Grid>
        </Dialog>
        <SnackBar val={snackData} />
        <div style={{ marginTop: 100 }}></div>
      </div>
    </ThemeProvider>
  );
}
